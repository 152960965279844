import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const isUrlAppToken = request.url.includes('/login/api/v1/login');

        if (isUrlAppToken && !isDevMode()) {
            const url = new URL(request.url);
            url.pathname = url.pathname.replace('/login', '');
            request = request.clone({
                url: url.toString()
            });
        }

        return next.handle(request);
    }
}
