import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'participacao'
    },
    {
        path: 'participacao',
        loadChildren: () =>
            import('./pages/participacao/participacao.module').then((m) => m.ParticipacaoModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.cadastro.participacoes.visualizar
        }
    },
    {
        path: 'convidado',
        loadChildren: () =>
            import('./pages/convidado/convidado.module').then((m) => m.ConvidadoModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.cadastro.convidados.visualizar
        }
    },
    {
        path: 'participantes',
        loadChildren: () =>
            import('./pages/calendario/participantes/participantes.module').then(
                (m) => m.ParticipantesModule
            ),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.calendario.participantes.visualizar
        }
    },
    {
        path: 'programa',
        loadChildren: () =>
            import('./pages/parametro/programa/programa.module').then((m) => m.ProgramaModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametrizacoes.programa.visualizar
        }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
