export const menuRoles = {
    itensMenu: {
        cadastro: {
            roles: [
                'CADASTRO_PARTICIPACOES_DELETAR',
                'CADASTRO_PARTICIPACOES_SALVAR',
                'CADASTRO_PARTICIPACOES_VISUALIZAR',
                'CADASTRO_CONVIDADOS_DELETAR',
                'CADASTRO_CONVIDADOS_SALVAR',
                'CADASTRO_CONVIDADOS_VISUALIZAR'
            ],
            participacoes: {
                visualizar: ['CADASTRO_PARTICIPACOES_VISUALIZAR'],
                salvar: ['CADASTRO_PARTICIPACOES_SALVAR'],
                deletar: ['CADASTRO_PARTICIPACOES_DELETAR']
            },
            convidados: {
                visualizar: ['CADASTRO_CONVIDADOS_VISUALIZAR'],
                salvar: ['CADASTRO_CONVIDADOS_SALVAR'],
                deletar: ['CADASTRO_CONVIDADOS_DELETAR']
            }
        },
        calendario: {
            roles: [
                'CALENDARIO_PARTICIPANTES_DELETAR',
                'CALENDARIO_PARTICIPANTES_SALVAR',
                'CALENDARIO_PARTICIPANTES_VISUALIZAR'
            ],
            participantes: {
                visualizar: ['CALENDARIO_PARTICIPANTES_VISUALIZAR'],
                salvar: ['CALENDARIO_PARTICIPANTES_SALVAR'],
                deletar: ['CALENDARIO_PARTICIPANTES_DELETAR']
            }
        },
        parametrizacoes: {
            roles: [
                'PARAMETRIZACAO_PROGRAMA_DELETAR',
                'PARAMETRIZACAO_PROGRAMA_SALVAR',
                'PARAMETRIZACAO_PROGRAMA_VISUALIZAR'
            ],
            programa: {
                visualizar: ['PARAMETRIZACAO_PROGRAMA_VISUALIZAR'],
                salvar: ['PARAMETRIZACAO_PROGRAMA_SALVAR'],
                deletar: ['PARAMETRIZACAO_PROGRAMA_DELETAR']
            }
        }
    }
};

/** Roles personalizadas conforme precisar em alguma aplicação
 * abaixo alguns exemplos
 */
export const rolesPersonalizadas = {};
